body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
* {
  scroll-behavior: smooth;
}
.dots-loading-container {
  position: fixed;
  inset: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  flex-direction: column !important;
  width: 100%;
  z-index: 11;
  background: #ffffff82;
}
.dots-loading {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.dots-loading div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: gray;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.dots-loading div:nth-child(1) {
  left: 8px;
  animation: b1 0.6s infinite;
}
.dots-loading div:nth-child(2) {
  left: 8px;
  animation: b2 0.6s infinite;
}
.dots-loading div:nth-child(3) {
  left: 32px;
  animation: b2 0.6s infinite;
}
.dots-loading div:nth-child(4) {
  left: 56px;
  animation: b3 0.6s infinite;
}
@keyframes b1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes b3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes b2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

* {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

/* #SearchName:hover {
  text-decoration: none;
  background: rgba(0, 0, 0, 0.04);
} */

